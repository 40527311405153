.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

form {
  background: #3b3b3b;
  padding: 20px;
  border-radius: 10px;
}

form div {
  margin: 10px;
}

label {
  margin-right: 10px;
}

input {
  padding: 5px;
}

button {
  background-color: #61dafb;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #21a1f1;
}
